const state = {
  Sidebar_drawer: null,
  SidebarColor: "success", //Change Sidebar Color || 'white', | "#2b2b2b" | "rgb(44, 59, 164)" | "rgb(96, 44, 164)" | "rgb(151, 210, 219)" | "rgb(77, 86, 100)"
  SidebarBg: "",
  navbarColor: "success",
  loading: false,
  loading2: false,
  user: {},
  customer: {},
  site: {},
  setHorizontalLayout: false,
  openSidebar: false,
};

const mutations = {
  SET_SIDEBAR_DRAWER(state, payload) {
    state.Sidebar_drawer = payload;
  },
  SET_SIDEBAR_COLOR(state, payload) {
    state.SidebarColor = payload;
  },
  SET_NAVBAR_COLOR(state, payload) {
    state.navbarColor = payload;
  },
  SET_USER(state, payload) {
    state.user = payload;
  },
  SET_TOKEN(state, payload) {
    state.token = payload;
  },
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  SET_LOADING2(state, payload) {
    state.loading2 = payload;
  },
  SET_CUSTOMER(state, payload) {
    state.customer = payload;
  },
  SET_SITE(state, payload) {
    state.site = payload;
  },
  SET_OPEN_SIDEBAR(state, payload) {
    state.openSidebar = payload;
  },
  SET_HORIZONTAL_LAYOUT(state, payload) {
    state.setHorizontalLayout = payload;
  },
};

const getters = {
  getLoading(state) {
    if (state.loading) {
      return state.loading;
    } else {
      return false;
    }
  },
  getLoading2(state) {
    if (state.loading2) {
      return state.loading2;
    } else {
      return false;
    }
  },
  getCustomer(state) {
    if (state.customer) {
      return state.customer;
    }
  },
  getHorizontalLayout(state) {
    return state.setHorizontalLayout;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
