import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("defaultSystem", ["user"]),
    ...mapState("login", ["admin_profile"])
  },
  methods: {
    $checkPermission(permission) {
      let role = JSON.parse(this.user.permissions);
      return role.some(item => item === permission);
    }
  }
};
