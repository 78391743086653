import Axios from "@/config/backend-service";

const state = {
  form: {},
  editedUserStore: {
    image_path: "",
    point: "",
    category: "",
    detail: "",
    name: "",
    type: "upload",
    start_date: null,
    end_date: null,
    schema_survey: {},
    is_permission_report: false,
    permission_admin: false,
    report_permission_email: "",
    show_time_selected: [],
    is_select_time: "",
    is_repeat: "",
    expected_num: "",
    expected_type: "percent",
    is_random_choice: false,
    is_random_question: false,
    is_check_in_only: false,
    kahoot_type: "",
    link_kahoot: "",
    typeReward: "point",
    status: "show",
    anonymous: false,
    target_department: null,
    is_use_expire_coin: false,
  },
  editedIndexStore: {},
  missionDetail: {},
  data: {},
  campaign: {}
};

const mutations = {
  SET_EDIT(state, payload) {
    state.editedUserStore = payload.editedUser;
    state.editedIndexStore = payload.editedIndex;
  },
  SET_MISSIONDETAIL(state, payload) {
    state.missionDetail = payload.missionDetail;
  },
  SET_FORM(state, payload) {
    state.form = payload;
  },
  SET_DATA_SITE(state, payload) {
    state.data = payload;
  },
  SET_MISSION_EXAM(state, payload) {
    state.missionExam = payload.missionExam;
  },
  SET_CAMPAIGN_USER(state, payload) {
    state.campaign = payload
  }
};

const actions = {
  getDataSite({ commit }) {
    Axios.get("getSiteInfo", {
      params: { domain_name: window.location.origin },
    })
      .then(({ data }) => {
        if (data.status == "success") {
          let dataSite = data.site;
          commit("SET_DATA_SITE", dataSite);
        }
      })
      .catch((err) => {
        this.$swal.fire({
          icon: "error",
          title: JSON.stringify(err.response.data),
          showConfirmButton: false,
          timer: 3000,
        });
      });
  },
};

const getters = {
  getForm(state) {
    if (state.form) {
      return ruleRequire(state.form.mySchema);
    }
  },
  getCoin(state) {
    if (state.data) {
      return state.data.point;
    }
  },
};
const required = (msg) => (v) => !!v || msg;
const requiredNormal = required("Erequired");

function ruleRequire(schema) {
  let item = Object.keys(schema);
  for (let index = 0; index < item.length; index++) {
    schema[item[index]].rules = [requiredNormal];
  }
  return schema;
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
