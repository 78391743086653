import Vue from "vue";
import "vuetify/dist/vuetify.min.css";
import "@/scss/vuetify/overrides.scss";

import Vuetify, {
  VRow,
  VCol,
  VTextField,
  VTooltip,
  VCheckbox,
  VCombobox,
  VSelect,
  VTextarea,
  VCard,
  VSheet,
} from "vuetify/lib";
import { Ripple, Intersect, Touch, Resize } from "vuetify/lib/directives";
Vue.use(Vuetify, {
  components: {
    VSheet,
    VCard,
    VCombobox,
    VTextarea,
    VRow,
    VTooltip,
    VCol,
    VTextField,
    VCheckbox,
    VSelect,
  },
  directives: { Ripple, Intersect, Touch, Resize },
});
const color = localStorage.getItem("color");
const configTheme = color ? color.includes('"') ? JSON.parse(color) : color : "#EE7D23";
const configThemeLight = color ? color.includes('"') ? JSON.parse(color) : color : "#EE7D23";

const theme = {
  primary: configTheme,
  info: "#1e88e5",
  // success: "#80BF4D6E",

  success: configTheme,
  accent: "#fc4b6c",
  default: "#563dea",
  lightPrimary: configThemeLight,

};

export default new Vuetify({
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
    dark: false, // If you want to set dark theme then dark:true else set to false
  },
  rtl: false, // If you want to set rtl theme then rtl:true else set to false
});
